.message-editor-toolbar {
  background: #ffffff;
  border: 1px solid #c1c1c1 !important;
}

.message-editor-wrapper {
  box-sizing: content-box;
}

.message-editor-editor {
  background: #ffffff;
  border: 1px solid #c1c1c1 !important;
  padding: 5px !important;
  border-radius: 2px !important;
  min-height: 150px;
}

.rdw-option-wrapper {
  border: 1px solid #c1c1c1;
  box-sizing: content-box;
}