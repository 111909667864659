#root {
  height: 100%;
}

.space-full {
  .ant-space-item {
    flex: 1 1 20%;
  }
}

.send-button-wrapper {
  display: flex;
  justify-content: end;
}