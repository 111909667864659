.custom-card {
  &__info-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
  }

  &__item-title {
    color: black;
    font-weight: 400;
    font-size: 13px;
  }

  &__item-text {
    color: black;
    font-weight: 500;
    font-size: 12px;
  }
}