.comments {
  margin-top: 20px;
  margin-bottom: 10px;
  background: #fff;

  .ant-list-header {
    padding: 15px;
  }

  .ant-spin-nested-loading {
    height: 500px;
    overflow-y: scroll;
    padding: 15px;
  }
}

.comment {
  margin-left: 60px;
  margin-top: 25px;

  &__author {
    color: #000c17;
    font-weight: bold;
  }

  &__date {
    color: #000c17;
  }

  .ant-comment {
    border: 1px solid rgba(0,0,0,.25);
    border-radius: 8px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: rgba(0,0,0,.05);
  }

  &--user {
    margin-right: 60px;
    margin-left: 0;

    .ant-comment {
      background-color: rgba(#1890ff,.15);
    }
  }
}

.application-page {
  .ant-descriptions-item-content, .ant-descriptions-item-label {
    padding: 6px 10px;
  }

  .ant-space-item {
    flex: 1 1 20%;
  }

  .send-button-wrapper {
    display: flex;
    justify-content: end;
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 0;
  }

  .ant-collapse-content-box {
    padding: 0;
  }

  &__horizontal {
    display: flex;
  }

  .row-success {
    background: rgba(#389e0d, .2);
  }
}