.ant-advanced-search-form .ant-form-item {
  margin-bottom: 10px;
}

.ant-advanced-search-form.ant-form-horizontal .ant-form-item-label {
  text-align: left;
  min-width: 111px;
}

.application-list-page__list {
  margin-top: 20px;

  .ant-tabs-tab {
    display: block;
    margin: 0 !important;
    padding: 0 !important;
  }

  .ant-tabs-content {
    height: 100%;
  }

  .ant-tabs-tabpane {
    padding: 0 !important;
  }

  .ant-tabs-nav {
    margin: 0;
  }
}

.application-list-page__tab {
  padding: 8px 24px;
  color: white;
}

.application-list-page__tab-pane {
  padding: 24px;
  height: 100%;
}

.smooth-dnd-container {
  header {
    justify-content: space-between;
  }
}