.login-form {
  &__wrapper {
    display: flex;
    height: 100%;
  }

  &__form {
    width: 350px;
    margin: auto;
  }

  &__button {
    width: 100%;
  }
}