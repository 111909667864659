.application-board {
  &__wrapper {
    position: relative;
  }
  &__switch {
    position: absolute;
    right: 100px;
    top: 10px;
  }
  &__button {
    position: absolute;
    right: 0;
    top: 5px;
  }
}